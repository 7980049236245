export const actionTypes = {

  // Base
  INIT_USER: 'INIT_USER',
  GET_USER: 'GET_USER',
  LOGOUT: 'LOGOUT',
  CHANGE_USER: 'CHANGE_USER',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',

  // Anonymous
  LOGIN_USER: 'LOGIN_USER',

  // Admin
  GET_INITIAL_METER_DATA: 'GET_INITIAL_METER_DATA',
  GET_CORRECTED_METER_DATA: 'GET_CORRECTED_METER_DATA',
  CREATE_CORRECTED_METER_DATA: 'CREATE_CORRECTED_METER_DATA',
  DELETE_CORRECTED_METER_DATA: 'DELETE_CORRECTED_METER_DATA',
  GET_CORRECTED_DATA_BY_METER: 'GET_CORRECTED_DATA_BY_METER',
  GET_BALANCES: 'GET_BALANCES',
  GET_SITES: 'GET_SITES',
  GET_USER_LIST: 'GET_USER_LIST',
  GET_SITE_STRUCTURES: 'GET_SITE_STRUCTURES',
  GET_EVENTS: 'GET_EVENTS',
  ADD_EVENT: 'ADD_EVENT',
  EDIT_EVENT: 'EDIT_EVENT',
  DELETE_EVENT: 'DELETE_EVENT',
  GET_EVENT_HISTORY: 'GET_EVENT_HISTORY',
  GET_ANALYTICS: 'GET_ANALYTICS',

  // User
}

export const mutationTypes = {
  // Base
  SET_LOADER: 'SET_LOADER',
  SET_TOKEN: 'SET_TOKEN',
  SET_AUTH: 'SET_AUTH',
  SET_USER: 'SET_USER',
  LOGOUT: 'LOGOUT',

  // Anonymous

  // Admin
  SET_INITIAL_METER_DATA: 'SET_INITIAL_METER_DATA',
  SET_INITIAL_METER_DATA_PAGE: 'SET_INITIAL_METER_DATA_PAGE',
  SET_INITIAL_METER_DATA_PAGES_COUNT: 'SET_INITIAL_METER_DATA_PAGES_COUNT',
  SET_INITIAL_METER_DATA_FILTERS: 'SET_INITIAL_METER_DATA_FILTERS',
  SET_CORRECTED_METER_DATA: 'SET_CORRECTED_METER_DATA',
  UPDATE_CORRECTED_METER_DATA: 'UPDATE_CORRECTED_METER_DATA',
  APPEND_CORRECTED_METER_DATA: 'APPEND_CORRECTED_METER_DATA',
  REMOVE_CORRECTED_DATA_BY_METER: 'REMOVE_CORRECTED_DATA_BY_METER',
  SET_CORRECTED_DATA_BY_METER: 'SET_CORRECTED_DATA_BY_METER',
  SET_USER_LIST: 'SET_USER_LIST',
  SET_SITE_STRUCTURES: 'SET_SITE_STRUCTURES',
  SET_EVENTS: 'SET_EVENTS',
  SET_EVENTS_PAGE: 'SET_EVENTS_PAGE',
  SET_EVENTS_FILTERS: 'SET_EVENTS_FILTERS',
  SET_EVENTS_PAGES_COUNT: 'SET_EVENTS_PAGES_COUNT',
  APPEND_EVENT: 'APPEND_EVENT',
  DELETE_EVENT: 'DELETE_EVENT',
  UPDATE_EVENT: 'UPDATE_EVENT',
  SET_EVENT_HISTORY: 'SET_EVENT_HISTORY',
  SET_EVENT_HISTORY_PAGE: 'SET_EVENT_HISTORY_PAGE',
  SET_EVENT_HISTORY_FILTERS: 'SET_EVENT_HISTORY_FILTERS',
  SET_EVENT_HISTORY_PAGES_COUNT: 'SET_EVENT_HISTORY_PAGES_COUNT',
  SET_BALANCES: 'SET_BALANCES',
  SET_BALANCES_FILTERS: 'SET_BALANCES_FILTERS',
  SET_BALANCES_PAGE: 'SET_BALANCES_PAGE',
  SET_BALANCES_PAGES_COUNT: 'SET_BALANCES_PAGES_COUNT',
  SET_SITES: 'SET_SITES',
  SET_CHART_TYPE: 'SET_CHART_TYPE',
  SET_ANALYTICS: 'SET_ANALYTICS'

  // User

  // User and admin
}
