import axios from 'axios'
import { userApiConfig } from '@base/configs'


export const initialMeterData = axios.create({
  baseURL: `${userApiConfig.baseURL}/meter_p30_data/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  params: {
    page: null
  }
})


export const correctedMeterData = axios.create({
  baseURL: `${userApiConfig.baseURL}/corrected_meter_data/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const correctedDataByMeter = axios.create({
  baseURL: `${userApiConfig.baseURL}/corrected_data_by_meter/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const balances = axios.create({
  baseURL: `${userApiConfig.baseURL}/balances/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  params: {
    page: null
  }
})


export const sites = axios.create({
  baseURL: `${userApiConfig.baseURL}/sites/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const getSiteStructures = axios.create({
  baseURL: `${userApiConfig.baseURL}/site_structures/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})


export const events = axios.create({
  baseURL: `${userApiConfig.baseURL}/events/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  params: {
    page: null
  }
})


export const eventHistory = axios.create({
  baseURL: `${userApiConfig.baseURL}/event_history/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  params: {
    page: null
  }
})


export const users = axios.create({
  baseURL: `${userApiConfig.baseURL}/users/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const analytics = axios.create({
  baseURL: `${userApiConfig.baseURL}/analytics/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})
