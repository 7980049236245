import i18n from '@base/i18n'

export const host = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_DOMAIN : 'localhost:8000'

export const webConfig = {
  baseURL: process.env.NODE_ENV === 'production' ? `https://${host}` : `http://${host}`,
}

export const apiConfig = {
  baseURL: `${webConfig.baseURL}/api/v1`
}

export const userApiConfig = {
  baseURL: `${apiConfig.baseURL}/user`
}

export const baseApiConfig = {
  baseURL: `${apiConfig.baseURL}/base`
}

export const loaderOptions = {
  color: '#4b8bff',
  backgroundColor: '#000',
  opacity: 0.1
}

// Field length settings
export const MIN_NAME_FIELD_LENGTH = 4
export const MAX_NAME_FIELD_LENGTH = 20
export const MIN_USERNAME_LENGTH = 4
export const MAX_USERNAME_LENGTH = 20
export const MIN_PASSWORD_LENGTH = 8

export const METER_QUANTITY = 50

export const BOOLEAN_OPTIONS = [
  { value: true, name: i18n.t('multiselect.single_label.boolean.true') },
  { value: false, name: i18n.t('multiselect.single_label.boolean.false') }
]

export const DATE_FORMAT = 'DD-MM-YYYY'
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm'
export const DATE_TIME_OUTPUT_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

export const DEFAULT_DATEPICKER_SHORTCUTS = [
  { key: 'thisWeek', label: i18n.t('datepicker.shortcuts.this_week'), value: 'isoWeek' },
  { key: 'lastWeek', label: i18n.t('datepicker.shortcuts.last_week'), value: '-isoWeek' },
  { key: 'last7Days', label: i18n.t('datepicker.shortcuts.last_7_days'), value: 7 },
  { key: 'last30Days', label: i18n.t('datepicker.shortcuts.last_30_days'), value: 30 },
  { key: 'thisMonth', label: i18n.t('datepicker.shortcuts.this_month'), value: 'month' },
  { key: 'lastMonth', label: i18n.t('datepicker.shortcuts.last_month'), value: '-month' },
  { key: 'thisYear', label: i18n.t('datepicker.shortcuts.this_year'), value: 'year' },
  { key: 'lastYear', label: i18n.t('datepicker.shortcuts.last_year'), value: '-year' }
]

export const ADMIN = 1
export const OPERATOR = 2
export const CHIEF_OPERATOR = 3
export const ANALYST = 4
export const CHIEF_ANALYST = 5
export const VIEWER = 6

export const USER_TYPES = [
  { name: i18n.t(`multiselect.single_label.user_type.${ADMIN}`), code: ADMIN },
  { name: i18n.t(`multiselect.single_label.user_type.${OPERATOR}`), code: OPERATOR },
  { name: i18n.t(`multiselect.single_label.user_type.${CHIEF_OPERATOR}`), code: CHIEF_OPERATOR },
  { name: i18n.t(`multiselect.single_label.user_type.${ANALYST}`), code: ANALYST },
  { name: i18n.t(`multiselect.single_label.user_type.${CHIEF_ANALYST}`), code: CHIEF_ANALYST },
  { name: i18n.t(`multiselect.single_label.user_type.${VIEWER}`), code: VIEWER },
]

export const EMERGENCY_SHUTDOWN = 1
export const DSO_APPLICATION = 2
export const INTERNAL_APPLICATION = 3
export const OPERATIONAL_SECURITY_TSO = 4
export const LOAD_REDUCING_RSE_TSO = 5

export const STRICT_DISCONNECTED_EVENT_TYPES = [
  EMERGENCY_SHUTDOWN
]

export const POSSIBLE_LIMITATION_EVENT_TYPES = [
  DSO_APPLICATION,
  INTERNAL_APPLICATION,
]

export const STRICT_LIMITATION_EVENT_TYPES = [
  OPERATIONAL_SECURITY_TSO,
  LOAD_REDUCING_RSE_TSO,
]

export const EVENT_TYPES = [
  {
    code: EMERGENCY_SHUTDOWN,
    name: i18n.t(`multiselect.single_label.events.${EMERGENCY_SHUTDOWN}`),
  },
  {
    code: DSO_APPLICATION,
    name: i18n.t(`multiselect.single_label.events.${DSO_APPLICATION}`),
  },
  {
    code: INTERNAL_APPLICATION,
    name: i18n.t(`multiselect.single_label.events.${INTERNAL_APPLICATION}`),
  },
  {
    code: OPERATIONAL_SECURITY_TSO,
    name: i18n.t(`multiselect.single_label.events.${OPERATIONAL_SECURITY_TSO}`),
  },
  {
    code: LOAD_REDUCING_RSE_TSO,
    name: i18n.t(`multiselect.single_label.events.${LOAD_REDUCING_RSE_TSO}`),
  },
]

export const USER_SECTION_PATH = '/user#/'
export const DJANGO_ADMIN_PATH_NAME = 'admin/'
