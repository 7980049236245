<template>
  <div class="footer">
    <footer class="d-flex justify-content-center p-3 bg-light">
      <span
        class="pre-wrap"
        v-html="$t('pages.footer.text', {
          currentYear: new Date().getFullYear()
        })"></span>
    </footer>
  </div>
</template>

<script>

  export default {
    name: 'CustomFooter',

    data () {
      return  {
      }
    }
  }
</script>

<style scoped>

</style>
