import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
import qs from 'qs'
import i18n from '@base/i18n'

import {
  baseState, baseGetters, baseMutations, baseActions,
  adminRequestInterceptor,
  adminResponseFulfilledInterceptor,
  adminResponseRejectedInterceptor,
} from '@base/store'
import { actionTypes, mutationTypes } from '@base/store/store-types'

import {
  initialMeterData,
  correctedMeterData,
  correctedDataByMeter,
  balances,
  sites,
  getSiteStructures,
  events,
  eventHistory,
  users,
  analytics,
} from '@user/services'

Vue.use(Vuex)

const initialState = {
  ...baseState,
  initialMeterData: [],
  initialMeterDataArg: {
    page: 1,
    pagesCount: 0,
    filters: null
  },
  correctedMeterData: [],
  correctedDataByMeter: [],
  balances: [],
  balancesArg: {
    page: 1,
    pagesCount: 0,
    filters: null
  },
  // There are three chart types: energy,
  // energyInstalledCapacityAc and energyInstalledCapacityDc
  chartType: 'energy',
  sites: [],
  siteStructures: [],
  events: [],
  eventsArg: {
    page: 1,
    pagesCount: 0,
    filters: {
      revoked: false
    }
  },
  eventHistoryList: [],
  eventHistoryListArg: {
    page: 1,
    pagesCount: 0,
    filters: null
  },
  userList: [],
  analytics: null
}

const axiosInstances = [
  initialMeterData,
  correctedMeterData,
  correctedDataByMeter,
  balances,
  sites,
  getSiteStructures,
  events,
  users,
  eventHistory,
  analytics,
]

for (const instance of axiosInstances) {
  instance.interceptors.request.use(adminRequestInterceptor)
  instance.interceptors.response.use(
    adminResponseFulfilledInterceptor,
    adminResponseRejectedInterceptor
  )
}

document.initialState = initialState      // Need in each section, stores for base logout user behavior

export default new Vuex.Store({
  state: initialState,
  getters: {
    ...baseGetters,

    getInitialMeterDataPage: state => state.initialMeterDataArg.page,
    getInitialMeterDataPagesCount: state => state.initialMeterDataArg.pagesCount,

    getBalancesPage: state => state.balancesArg.page,
    getBalancesPagesCount: state => state.balancesArg.pagesCount,

    getEventsPage: state => state.eventsArg.page,
    getEventsPagesCount: state => state.eventsArg.pagesCount,

    getEventHistoryPage: state => state.eventHistoryListArg.page,
    getEventHistoryPagesCount: state => state.eventHistoryListArg.pagesCount,

    getHourBalancesBySiteName: (state) => (siteName) => {
      const balanceArray = []
      // Filter balance records by site name, and zip dates
      // and values for that dates
      state.balances.filter(balance => {
        return balance.site === siteName
      }).map(obj => {
        balanceArray.push(..._.zip(obj.timeIndexesUtc, obj[state.chartType]))
      })
      // Parse all dates and values for that Site, and return its
      const dates = balanceArray.map(elem => {
        return [new Date(elem[0])]
      })
      const values = balanceArray.map(elem => {
        return elem[1]
      })
      return [dates, values]
    },

    getHourFormattedChartsData: (state, getters) => {
      // Init formatted array, which we will return, and array for balance
      // values and corresponding site names
      let formattedChartsData = []
      let allBalanceValues = {
        siteNames: [],
        values: []
      }
      state.sites.forEach(function (site) {
        const [dates, values] = getters.getHourBalancesBySiteName(site.displayableName)
        // As each balance object has the same date values (1 hour frequency),
        // we set for our formatted array maximum date range for all charts
        if (dates.length > formattedChartsData.length)
          formattedChartsData = dates
        // If Site has values, then we push values and siteName to our
        // total balance values array
        if (values.length) {
          allBalanceValues.values.push(values)
          allBalanceValues.siteNames.push(site.displayableName)
        }
      })
      // Now we have array of arrays of values (allBalanceValues)
      // Also we have our formatted array with array of arrays of dates
      // Now we need to iterate through all arrays of balance values,
      // inside it go through all dates, and append each new value to
      // corresponding date
      allBalanceValues.values.forEach(valueArray => {
        formattedChartsData.map((elem, index) => {
          elem.push(valueArray[index])
        })
      })
      // Set columns for charts, first goes Date, then site names
      // (we get it from array with values)
      formattedChartsData.unshift([
        i18n.t('charts.balances.column_date'), ...allBalanceValues.siteNames
      ])
      // Return formatted array
      return formattedChartsData
    },

    getDayBalancesBySiteName: (state) => (siteName) => {
      const balanceArray = []
      // Filter balance records by site name, and zip dates
      // and values for that dates
      state.balances.filter(balance => {
        return balance.site === siteName
      }).map(obj => {
        balanceArray.push(..._.zip(obj.timeIndexesUtc, obj[state.chartType]))
      })
      // Parse all dates and values for that Site, and return it
      // Filter same dates and leave only unique days
      const dates = balanceArray.filter((elem, index) => {
        if (index > 0) {
          if (new Date(elem[0]).getDate() === new Date(balanceArray[index - 1][0]).getDate()) {
            return false
          }
        }
        return true
      }).map(elem => {
        return [new Date(elem[0])]
      })

      // Sum all balances by each day in dates array
      const values = dates.map(date => {
        return balanceArray.filter(elem => {
          return new Date(elem[0]).getDate() === date[0].getDate()

        }).reduce((acc, val) => acc + val[1], 0)
      })

      return [dates, values]
    },

    getDayLossesBySiteName: (state) => (siteName) => {
      const losses = []
      state.balances.filter(balance => {
        return balance.site === siteName
      }).map(obj => {
        losses.push(obj.lossesFilters[state.chartType])
      })
      return losses
    },

    getDayFormattedChartsData: (state, getters) => {
      // Init formatted array, which we will return, and array for balance
      // values and corresponding site names
      let formattedChartsData = []
      let allBalanceValues = {
        siteNames: [],
        values: [],
        dates: [],
        losses: []
      }
      state.sites.forEach(function (site) {
        const [dates, values] = getters.getDayBalancesBySiteName(site.displayableName)
        const losses = getters.getDayLossesBySiteName(site.displayableName)
        // If Site has values, then we push values and siteName to our
        // total balance values array
        if (dates.length > formattedChartsData.length)
          formattedChartsData = dates
        // If Site has values, then we push values and siteName to our
        // total balance values array
        if (values.length) {
          allBalanceValues.values.push(values)
          allBalanceValues.siteNames.push(site.displayableName)
          allBalanceValues.losses.push(losses)
        }
      })
      // Now we have array of arrays of values (allBalanceValues)
      // Also we have our formatted array with array of arrays of dates
      // Now we need to iterate through all arrays of balance values,
      // inside it go through all dates, and append each sum all balances
      // for all sites to each date
      allBalanceValues.values.forEach((valueArray, valueIndex) => {
        formattedChartsData.map((elem, index) => {
          if (valueIndex === 0) {
            elem.push(valueArray[index])
          } else {
            elem[1] += valueArray[index]
          }
        })
      })
      allBalanceValues.losses.forEach((valueArray, valueIndex) => {
        formattedChartsData.map((elem, index) => {
          if (valueIndex === 0) {
            elem.push(valueArray[index])
          } else {
            elem[2] += valueArray[index]
          }
        })
      })
      // Set columns for charts, first goes Date, then balances, then losses
      formattedChartsData.unshift([
        i18n.t('charts.balances.column_date'),
        i18n.t('charts.balances.column_balances'),
        i18n.t('charts.balances.column_losses')
      ])
      // Return formatted array
      return formattedChartsData
    },

  },

  mutations: {
    ...baseMutations,

    [mutationTypes.SET_INITIAL_METER_DATA](state, data) {
      state.initialMeterData = data
    },
    [mutationTypes.SET_INITIAL_METER_DATA_PAGE] (state, page) {
      state.initialMeterDataArg.page = page
    },
    [mutationTypes.SET_INITIAL_METER_DATA_PAGES_COUNT] (state, count) {
      state.initialMeterDataArg.pagesCount = count
    },
    [mutationTypes.SET_INITIAL_METER_DATA_FILTERS] (state, filters) {
      state.initialMeterDataArg.filters = filters
    },

    [mutationTypes.SET_CORRECTED_METER_DATA](state, data) {
      state.correctedMeterData = data
    },
    [mutationTypes.APPEND_CORRECTED_METER_DATA](state, data) {
      state.correctedMeterData.unshift(data)
    },
    [mutationTypes.REMOVE_CORRECTED_DATA_BY_METER](state, id) {
      const oldDataIndex = state.correctedDataByMeter.findIndex(obj => obj.id === id)
      if (oldDataIndex >= 0) {
        state.correctedDataByMeter.splice(oldDataIndex, 1)
      }
    },
    [mutationTypes.SET_USER_LIST](state, users) {
      state.userList = users
    },

    [mutationTypes.SET_SITE_STRUCTURES](state, structures) {
      state.siteStructures = structures
    },

    [mutationTypes.SET_EVENTS](state, events) {
      state.events = events
    },
    [mutationTypes.SET_EVENTS_PAGE] (state, page) {
      state.eventsArg.page = page
    },
    [mutationTypes.SET_EVENTS_FILTERS] (state, filters) {
      state.eventsArg.filters = filters
    },
    [mutationTypes.SET_EVENTS_PAGES_COUNT] (state, count) {
      state.eventsArg.pagesCount = count
    },
    [mutationTypes.APPEND_EVENT](state, event) {
      const oldEventIndex = state.events.findIndex(obj => obj.group === event.group)
      if (oldEventIndex >= 0)
        state.events.splice(oldEventIndex, 1)
      state.events.unshift(event)
    },
    [mutationTypes.DELETE_EVENT](state, eventId) {
      const oldEventIndex = state.events.findIndex(event => event.id === eventId)
      if (oldEventIndex >= 0)
        state.events.splice(oldEventIndex, 1)
    },
    [mutationTypes.UPDATE_EVENT](state, event) {
      const oldEventObj = state.events.find(obj => obj.group === event.group)
      if (oldEventObj)
        Object.assign(oldEventObj, event)
    },

    [mutationTypes.SET_EVENT_HISTORY](state, history) {
      state.eventHistoryList = history
    },
    [mutationTypes.SET_EVENT_HISTORY_PAGE] (state, page) {
      state.eventHistoryListArg.page = page
    },
    [mutationTypes.SET_EVENT_HISTORY_FILTERS] (state, filters) {
      state.eventHistoryListArg.filters = filters
    },
    [mutationTypes.SET_EVENT_HISTORY_PAGES_COUNT] (state, count) {
      state.eventHistoryListArg.pagesCount = count
    },

    [mutationTypes.SET_CORRECTED_DATA_BY_METER](state, data) {
      state.correctedDataByMeter = data
    },

    [mutationTypes.SET_BALANCES](state, data) {
      state.balances = data
    },
    [mutationTypes.SET_BALANCES_PAGE] (state, page) {
      state.balancesArg.page = page
    },
    [mutationTypes.SET_BALANCES_PAGES_COUNT] (state, count) {
      state.balancesArg.pagesCount = count
    },
    [mutationTypes.SET_BALANCES_FILTERS] (state, filters) {
      state.balancesArg.filters = filters
    },

    [mutationTypes.SET_SITES](state, data) {
      state.sites = data
    },
    [mutationTypes.SET_CHART_TYPE](state, type) {
      state.chartType = type
    },

    [mutationTypes.SET_ANALYTICS](state, analytics) {
      state.analytics = analytics
    },

  },

  actions: {
    ...baseActions,

    [actionTypes.GET_INITIAL_METER_DATA]({ state, commit }) {
      initialMeterData.defaults.params.page = state.initialMeterDataArg.page
      return new Promise((resolve, reject) => {
        initialMeterData({
          params: state.initialMeterDataArg.filters,
          // for filtering by multiple site names
          paramsSerializer: function(params) {
            return qs.stringify(params, { indices: false })
          }
        })
          .then(response => {
            commit(mutationTypes.SET_INITIAL_METER_DATA, response.data.results)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_CORRECTED_METER_DATA]({ commit }) {
      return new Promise((resolve, reject) => {
        correctedMeterData()
          .then(response => {
            commit(mutationTypes.SET_CORRECTED_METER_DATA, response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    [actionTypes.CREATE_CORRECTED_METER_DATA]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        correctedMeterData({
          method: 'post',
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.APPEND_CORRECTED_METER_DATA, response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    [actionTypes.DELETE_CORRECTED_METER_DATA]({ commit }, id) {
      return new Promise((resolve, reject) => {
        correctedMeterData({
          url: `${id}/`,
          method: 'delete'
        })
          .then(response => {
            commit(mutationTypes.REMOVE_CORRECTED_DATA_BY_METER, id)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_CORRECTED_DATA_BY_METER]({ commit }, meterId) {
      return new Promise((resolve, reject) => {
        correctedDataByMeter({
          url: `${meterId}/`
        })
          .then(response => {
            commit(mutationTypes.SET_CORRECTED_DATA_BY_METER, response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_BALANCES]({ state, commit }) {
      balances.defaults.params.page = state.balancesArg.page
      return new Promise((resolve, reject) => {
        balances({
          params: state.balancesArg.filters,
          // for filtering by multiple site names
          paramsSerializer: function(params) {
            return qs.stringify(params, { indices: false })
          }
        })
          .then(response => {
            commit(mutationTypes.SET_BALANCES, response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_SITES]({ commit }) {
      return new Promise((resolve, reject) => {
        sites()
          .then(response => {
            commit(mutationTypes.SET_SITES, response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    [actionTypes.GET_USER_LIST]({ commit }) {
      return new Promise((resolve, reject) => {
        users()
          .then(response => {
            commit(mutationTypes.SET_USER_LIST, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_SITE_STRUCTURES]({ commit }) {
      return new Promise((resolve, reject) => {
        getSiteStructures()
          .then(response => {
            commit(mutationTypes.SET_SITE_STRUCTURES, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_EVENTS]({ commit, state }) {
      events.defaults.params.page = state.eventsArg.page
      return new Promise((resolve, reject) => {
        events({
          params: state.eventsArg.filters,
          // for filtering by multiple site names
          paramsSerializer: function(params) {
            return qs.stringify(params, {indices: false})
          }
        })
          .then(response => {
            if (response.data.results)
              commit(mutationTypes.SET_EVENTS, response.data.results)
            else
              commit(mutationTypes.SET_EVENTS, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.ADD_EVENT]({ commit }, payloads) {
      return new Promise((resolve, reject) => {
        events({
          method: 'post',
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.APPEND_EVENT, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.EDIT_EVENT]({ commit }, { payloads, id}) {
      return new Promise((resolve, reject) => {
        events({
          method: 'put',
          url: `${id}/`,
          data: payloads
        })
          .then(response => {
            commit(mutationTypes.UPDATE_EVENT, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [actionTypes.DELETE_EVENT]({ commit }, id) {
      return new Promise((resolve, reject) => {
        events({
          method: 'delete',
          url: `${id}/`,
        })
          .then(response => {
            commit(mutationTypes.DELETE_EVENT, id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_EVENT_HISTORY]({ commit, state }, eventId) {
      eventHistory.defaults.params.page = state.eventHistoryListArg.page
      return new Promise((resolve, reject) => {
        eventHistory({
          url: `${eventId}/`,
          params: state.eventHistoryListArg.filters,
          // for filtering by multiple site names
          paramsSerializer: function(params) {
            return qs.stringify(params, {indices: false})
          }
        })
          .then(response => {
            if (response.data.results)
              commit(mutationTypes.SET_EVENT_HISTORY, response.data.results)
            else
              commit(mutationTypes.SET_EVENT_HISTORY, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    [actionTypes.GET_ANALYTICS]({ commit, state }) {
      return new Promise((resolve, reject) => {
        analytics({
          params: state.eventsArg.filters,
          // for filtering by multiple site names
          paramsSerializer: function(params) {
            return qs.stringify(params, {indices: false})
          }
        })
          .then(response => {
            commit(mutationTypes.SET_ANALYTICS, response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  }
})
